// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.

// Using a bundler like webpack
import GLightbox from "glightbox";
import {FloatLabels} from "@keodesign/float-labels";
import Splide from "@splidejs/splide";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";

export function init() {
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
	setupSlider();
	new FloatLabels(".js-float-wrap");
	reveal();
	filter_casestudy_on_submit();
	ProportionalImageNormalisation(".scale-me", 120, 0.525);
	randomClient();
	
	hookBootstrapCarouselSlide();
}

export function hookBootstrapCarouselSlide(){
	const myCarousel = document.getElementById("banners-home");
	if(myCarousel){
		myCarousel.addEventListener("slide.bs.carousel", () => {
			fixSplashHeight();
		});
		window.addEventListener("resize", fixSplashHeight);
	}
	
}
export function fixSplashHeight(){
	const splash = document.querySelector("#splash-wrap");

	if (splash){
		let splashHeight = 200;
		const header = document.querySelector("header");
		if (header){
			splashHeight += header.offsetHeight;
		}
 
		const item_list = document.querySelectorAll(".carousel-content");
		let maxHeight = 0;
	
		for (var i = 0; i < item_list.length; i++) {
			var elementHeight = item_list[i].offsetHeight;
			maxHeight = Math.max(maxHeight, elementHeight);
		}
		splashHeight += maxHeight;
		splash.style.height = "max(100vh, " + splashHeight + "px)";
	}

}
export function randomClient(){
	var client_wrappers = document.querySelectorAll(".random-list");
	if (client_wrappers){
		client_wrappers.forEach( (client_wrapper) =>{
			const randomChildren = getRandomNonRepeatingChildren(client_wrapper, 5);
			randomChildren.forEach(child => {
				child.classList.remove("d-none");
			});
		});
	}

}

function getRandomNonRepeatingChildren(parent, count) {
	const children = Array.from(parent.children);
	const selectedChildren = [];

	if (count > children.length) {
		console.error("Count cannot exceed the number of children.");
		return;
	}

	while (selectedChildren.length < count) {
		const randomIndex = Math.floor(Math.random() * children.length);
		const randomChild = children.splice(randomIndex, 1)[0];
		selectedChildren.push(randomChild);
	}

	return selectedChildren;
}

export function setupSlider(){
	var elms = document.getElementsByClassName("slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "slide",
			rewind    : true,
			pagination: false,
			arrows    : false,
			autoplay: true,
		}).mount();
	}
}

export function reveal() {
	var reveals = document.querySelectorAll(".reveal");
	var scrollPosition = document.querySelector("html").scrollTop;

	
	for (var i = 0; i < reveals.length; i++) {
		var windowHeight = window.innerHeight;
		
		var elementTop = reveals[i].getBoundingClientRect().top;
		var elementVisible = 80;

		if (elementTop < windowHeight - elementVisible || scrollPosition <= 100) {
			reveals[i].classList.add("active");
		} else {
			reveals[i].classList.remove("active");
		}
	}

}

export function filter_casestudy_on_submit() {
	const filter_casestudy = document.querySelector("#filter_casestudy");
	if (filter_casestudy){
		filter_casestudy.addEventListener("submit", (event) => {
			event.preventDefault();
			
			const formFields = filter_casestudy.elements;

			// Iterate through the form fields
			for (let i = formFields.length -1; i >= 0; i--) {
				let field = formFields[i];
	
				// Check if the field is not a button and is blank
				if (field.type !== "submit" && field.value.trim() === "") {
					// Remove the blank field
					field.name = "";
				}
			}
			filter_casestudy.submit();
		});
	}
}

window.addEventListener("scroll", reveal);
window.addEventListener("DOMContentLoaded", init);
